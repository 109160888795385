import request from '@/axios'

const api = '/product-api/product/'

// 分页查询产品基础信息
export const getTableList = (params) => {
  return request.get({ url: `${api}info/page`, params })
}

// 修改产品状态
export const updateStatus = (params) => {
  return request.put({ url: `${api}update/state`, params })
}

// 所属产品方 list
export const productList = () => {
  return request.get({ url: `/product-api/side/all/name` })
}

// 保存产品
export const saveProduct = (data) => {
  return request.post({ url: `${api}info/save`, data })
}

// 更新产品
export const updateProduct = (data) => {
  return request.post({ url: `${api}info/update`, data })
}
// 查询产品详情
export const productDetails = (productId) => {
  return request.get({ url: `${api}details/${productId}` })
}

// 产品审核记数接口
export const productCount = () => {
  return request.get({ url: `${api}approval/count/info` })
}
