import {
  defaultTo,
  find,
  forEach,
  get,
  includes,
  isArray,
  isEmpty,
  isNull,
  map,
  mapValues,
  merge,
  round,
  split,
  transform
} from 'lodash-es'
import { ElLoading } from 'element-plus'
import { formatToDateDefault, formatToDateTime } from './dateUtil'
import { reactive } from 'vue'
import dayjs from 'dayjs'

export const searchFormatDate = (parmas, key = 'date') => {
  const date = get(parmas, key)
  return Object.assign(parmas, date ? formatToDateDefault(date) : {})
}

export const dictdeft = (deptlist, id) => {
  const items = deptlist.find((res) => {
    if (res.id == id) {
      return res
    } else if (res.children && res.children.length > 0) {
      dictdeft(res.children, id)
    }
  })

  return items
}

export const getFormField = (formFields) => {
  return map(formFields, 'field')
}
/**
 *
 * @param statusList 数组
 * @param value 状态
 * @returns
 */
export const findLabelByValue = (statusList, value, label = 'label') => {
  if (Array.isArray(value)) {
    return value.map((val) => {
      const item = find(statusList, ['value', val])
      return item ? item[label] : `--`
    })
  } else {
    const item = find(statusList, ['value', value])
    return item ? item[label] : '--'
  }
}

export const formatSelectData = (array, labelKey, valKey, key = '') => {
  return map(array, (v) => ({
    label: v[labelKey],
    value: v[valKey],
    key: v[key]
  }))
}
/**
 *
 * @param time 时分秒 时间戳
 * @returns
 */
export const countDown = (time: string) => {
  const data = new Date(time)
  return data.getTime()
}
/**
 *
 * @param time 时分秒 时间
 * @returns
 */
export const formatDuring = (mss) => {
  const now = new Date(mss)
  const hours = now.getHours().toString().padStart(2, '0')
  const minutes = now.getMinutes().toString().padStart(2, '0')
  const seconds = now.getSeconds().toString().padStart(2, '0')
  return `${hours}:${minutes}:${seconds}`
}

export const packdateTime = () => {
  const defaultTime = reactive([new Date(0, 0, 0, 0, 0, 0), new Date(0, 0, 0, 23, 59, 59)])
  const disabledDate = (time) => {
    const date = new Date()
    return (
      time.getTime() >
      new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59).getTime()
    )
  }
  return { defaultTime, disabledDate }
}

export const formatTime = (time: string) => {
  return formatToDateTime(time, 'HH:mm:ss')
}
/**
 * 拼接日期时间回显
 * @param time
 * @returns
 */
export const viewTime = (timeStrings) => {
  return timeStrings.map((timeStr) => {
    return dayjs().format('YYYY-MM-DD ') + timeStr
  })
}

export const formattedData = (data, str = '--') => {
  return transform(data, (result: { [key: string]: any }, value, key: string) => {
    result[key] = defaultTo(value, str)
  })
}

export interface AreaNode {
  code: string
  name: string
  firstName: string
  level: string
  pcode?: string | null
  pname?: string
  firstPname?: string
  fullname: string
  longitude: string
  latitude: string
  label: string
  value: string
  children?: AreaNode[]
}

/**
 * city 转为select-tree格式
 * @param data
 * @returns
 */
export const treeCity = (data: AreaNode[]): AreaNode[] => {
  const tree: AreaNode[] = []
  const childrenMap: Record<string, AreaNode> = {}

  // 构建映射表和初始化children
  forEach(data, (item) => {
    childrenMap[item.code] = {
      ...item,
      children: [],
      label: get(item, 'name'),
      value: get(item, 'code')
    }
  })

  // 根据pcode构建树结构
  forEach(data, (item) => {
    const parent = item.pcode ? childrenMap[item.pcode] : null
    if (parent) {
      parent.children?.push({
        ...childrenMap[item.code],
        label: get(childrenMap[item.code], 'name'),
        value: get(childrenMap[item.code], 'code')
      })
    } else {
      tree.push({
        ...childrenMap[item.code],
        label: get(childrenMap[item.code], 'name'),
        value: get(childrenMap[item.code], 'code')
      })
    }
  })

  return tree
}
/**
 * @param data  树结构 数组
 * @param maxLevel 循环最大深度
 * @param currentLevel
 * @returns
 */
export const generateTreeData = (data, maxLevel = 2, currentLevel = 1) => {
  return data.map((item) => {
    const newItem = { ...item }
    if (currentLevel < maxLevel && newItem.children && newItem.children.length) {
      newItem.children = generateTreeData(newItem.children, maxLevel, currentLevel + 1)
    } else {
      delete newItem.children // 如果达到最大层级，移除children属性
    }
    return newItem
  })
}
/**
 * 格式化金额
 * @param amount 金额
 * @returns
 */
export const formatAmountWith = (amount) => {
  // 将数值转换为字符串，以便操作小数点后的部分
  amount = !amount || amount === '--' ? 0 : amount
  const amountStr = amount.toString()
  // 使用正则表达式检查小数点后是否有非零数字
  if (amountStr.includes('.') && /\.\d*0+$/.test(amountStr)) {
    // 如果小数点后都是零，则用parseFloat去除多余的零
    return parseFloat(amountStr)
  } else {
    // 否则，直接返回原数值的字符串形式
    return parseFloat(amountStr)
  }
}

export const objToformatAmount = (obj, _keys) => {
  const result = mapValues(obj, (value, key) => {
    if (includes(_keys, key)) {
      return value === '--' || isEmpty(value) ? '--' : round(parseFloat(value), 2)
    }
    return value
  })
  return {
    ...obj,
    ...result
  }
}

/**
 * arr 数组
 * ArrKey id集合
 */
export const arrDefaultSelection = (arr: Array<any>, ArrKey: any, bool = true) => {
  if (bool) {
    if (ArrKey == undefined || ArrKey == null || ArrKey.length == 0) {
      arr[0].checked = true
      return arr
    }
  }
  arr.forEach((res) => {
    ArrKey.forEach((item) => {
      if (item == res.value) {
        res.checked = true
      }
    })
  })

  return arr
}

export const transformArrayValuesToString = (obj, separator = ',') => {
  return mapValues(obj, (value) => {
    if (isArray(value)) {
      return value.join(separator)
    }
    return value
  })
}

export const convertKeysToArray = (obj, keys) => {
  return mapValues(obj, (value, key) => {
    if (keys.includes(key)) {
      if (!isNull(value)) {
        return map(split(value, ','), (v) => {
          return Number(v)
        })
      }
      return []
    } else {
      return value
    }
  })
}

export const isBlob = (value) => {
  return value instanceof Blob
}

/**
 * 下载
 * @param blobData
 * @param mimeTypeStr 类型
 * @param fileName
 */
export const downloadFileByFileFlow = (blobData, mimeTypeStr, fileName = '') => {
  const blob = new Blob([blobData], { type: mimeTypeStr })
  const blobURL = window.URL.createObjectURL(blob)
  const tempLink = document.createElement('a')
  tempLink.style.display = 'none'
  tempLink.href = blobURL
  tempLink.setAttribute('download', decodeURI(fileName))
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank')
  }
  document.body.appendChild(tempLink)
  tempLink.click()
  const loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  document.body.removeChild(tempLink)
  window.URL.revokeObjectURL(blobURL)
  setTimeout(() => {
    loading.close()
  }, 2000)
}

/**
 * null 默认 --
 * @param arr
 * @returns
 */
export const replaceNullsWithDash = (
  arr,
  filterArr = ['alipayUrl', 'wechartUrl'],
  condition = [null] as Array<string | null | number | null>
) => {
  return isArray(arr)
    ? map(arr, (obj) =>
        merge(
          {},
          obj,
          mapValues(obj, (value, key) =>
            includes(condition, value) && !includes(filterArr, key) ? '--' : value
          )
        )
      )
    : merge(
        {},
        arr,
        mapValues(arr, (value, key) =>
          includes(condition, value) && !includes(filterArr, key) ? '--' : value
        )
      )
}
