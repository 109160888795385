/**
 * Independent time operation tool to facilitate subsequent switch to dayjs
 */
import dayjs from 'dayjs'
import { isArray } from 'lodash-es'

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const DATE_FORMAT = 'YYYY-MM-DD'

export function formatToDateTime(date?: dayjs.ConfigType, format = DATE_TIME_FORMAT): string {
  return dayjs(date).format(format)
}

export function formatToDate(date?: dayjs.ConfigType, format = DATE_FORMAT): string {
  return dayjs(date).format(format)
}

export function formatToDateDefault(date?: Array<dayjs.ConfigType>): object {
  const start_date = `${dayjs(date && date[0]).format(DATE_FORMAT)}`
  const end_date = `${dayjs(date && date[1]).format(DATE_FORMAT)}`
  const startTime = `${start_date} 00:00:00`
  const endTime = `${end_date} 23:59:59`
  return {
    statDate: `${startTime},${endTime}`,
    clickTime: [startTime, endTime],
    statisticDate: [startTime, endTime],
    createTime: [startTime, endTime],
    statisticsOfStartTime: start_date,
    statisticsOfEndTime: end_date,
    startTime,
    endTime,
    recordTime: [startTime, endTime]
  }
}

export function formatToDateStatistics(
  date?: Array<dayjs.ConfigType>,
  start = 'startTime',
  end = 'endTime'
): object {
  const startTime = `${dayjs(date && date[0]).format(DATE_FORMAT)}`
  const endTime = `${dayjs(date && date[1]).format(DATE_FORMAT)}`
  return {
    [start]: startTime,
    [end]: endTime
  }
}

export const dateToDay = (day: number | Array<number>) => {
  const currentDay = dayjs()
  return isArray(day)
    ? [currentDay.subtract(day[0], 'day'), currentDay.subtract(day[1], 'day')]
    : [currentDay.subtract(day, 'day'), currentDay]
}

export const dateUtil = dayjs
