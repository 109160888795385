import { indexOf, keys, split } from 'lodash-es'
import service, { PATH_URL } from './service'
import { CONTENT_TYPE } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'

export const local = {
  'system-api': {
    prot: 9803,
    url: 'system'
  },
  'channel-api': {
    prot: 9806,
    url: 'channel'
  },
  'product-api': {
    prot: 9815,
    url: 'product'
  },
  'finance-api': {
    prot: 9807,
    url: 'adapter-finance'
  },
  'legal-admin-api': {
    prot: 9809,
    url: 'legal'
  },
  'org-api': {
    prot: 9810,
    url: 'adapter-org-manage'
  },
  'statistics-api': {
    prot: 9810,
    url: 'statistics'
  },
  'msg-notify': {
    prot: 9810,
    url: 'notify'
  },
  client: {
    prot: 9803,
    url: 'client-user'
  }
}

const request = (option: AxiosConfig) => {
  const { url, method, params, data, headers, responseType } = option

  const _keys = split(url, '/')
  const contentType =
    indexOf(_keys, 'export') === -1 ? CONTENT_TYPE : `application/x-www-form-urlencoded`
  const prot = indexOf(keys(local), _keys[1]) === -1 ? 'system-api' : _keys[1]
  const userStore = useUserStoreWithOut()
  return service.request({
    baseURL: import.meta.env.MODE === 'native' ? `${PATH_URL}:${local[prot]['prot']}` : undefined,
    url: `/${local[prot]['url']}${url}`,
    method,
    params,
    data: data,
    responseType: responseType,
    headers: {
      'Content-Type': contentType,
      [userStore.getTokenKey ?? 'Authorization']: userStore.getToken ?? '',
      ...headers
    }
  })
}

export default {
  get: <T = any>(option: AxiosConfig) => {
    return request({ method: 'get', ...option }) as Promise<IResponse<T>>
  },
  post: <T = any>(option: AxiosConfig) => {
    return request({ method: 'post', ...option }) as Promise<IResponse<T>>
  },
  delete: <T = any>(option: AxiosConfig) => {
    return request({ method: 'delete', ...option }) as Promise<IResponse<T>>
  },
  put: <T = any>(option: AxiosConfig) => {
    return request({ method: 'put', ...option }) as Promise<IResponse<T>>
  },
  cancelRequest: (url: string | string[]) => {
    return service.cancelRequest(url)
  },
  getblob: <T = any>(option: AxiosConfig) => {
    return request({ method: 'get', ...option, responseType: 'blob' }) as Promise<IResponse<T>>
  },
  cancelAllRequest: () => {
    return service.cancelAllRequest()
  }
}
