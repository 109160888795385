import request from '@/axios'
import { menu } from '@/types/Munu/index'
export const getMenuListApi = (params) => {
  return request.get<menu>({ url: `/system-api/system/menu/list`, params })
}
export const getMenuTreeApi = () => {
  return request.get({ url: `/system-api/system/menu/treeselect` })
}
export const getMenuListroleApi = (roleId) => {
  return request.get({ url: `/system-api/system/menu/roleMenuTreeselect/${roleId}` })
}
export const getMenuListremoveApi = (menuId) => {
  return request.delete<menu>({ url: `/system-api/system/menu/${menuId}` })
}
export const getMenuListinfoApi = (menuId) => {
  return request.get<menu>({ url: `/system-api/system/menu/${menuId}` })
}
export const getMenuListeditApi = (data) => {
  return request.put<menu>({ url: `/system-api/system/menu/`, data })
}
export const getMenuListAddApi = (data) => {
  return request.post<menu>({ url: `/system-api/system/menu/`, data })
}
export const getMenugetRoutersApi = (Type) => {
  return request.get<menu>({ url: `/system-api/getRouters/?deviceType=${Type}` })
}
export const getroleMenuTreeselectApi = (roleId) => {
  return request.get<menu>({ url: `/system-api/system/menu/roleMenuTreeselect/${roleId}` })
}
//审批计数消息
export const getRoleCountinfoApi = () => {
  return request.get({ url: `/org-api/org/approval/count/info` })
}
// 获取审核记录数
export const getRecordAmount = () => {
  return request.get({
    url: '/channel-api/finance/finance-audit-record/get-audit-record-amount'
  })
}
